import { Middleware } from '@reduxjs/toolkit';
import { RootState, TypedDispatch } from 'redux/store';
import { CheckoutContext, PreOrder } from '@akinon/next/types';
import { setSampleProducts } from '@theme/redux/reducers/sampleProducts';

interface CheckoutResult {
  payload: {
    errors?: any;
    pre_order?: PreOrder;
    context_list?: CheckoutContext[];
  };
}

interface MiddlewareParams {
  getState: () => RootState;
  dispatch: TypedDispatch;
}

export const sampleContextListMiddleware: Middleware = ({
  dispatch
}: MiddlewareParams) => {
  return (next) => (action) => {
    const result: CheckoutResult = next(action);

    if (result?.payload?.context_list) {
      result.payload.context_list.forEach((context) => {
        if (context?.page_slug == 'sampleproductpage') {
          dispatch(
            setSampleProducts({
              ...context.page_context,
              isSampleProductActive: true
            })
          );
        }
      });
    }

    return result;
  };
};
