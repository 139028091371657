import { PreOrder } from '@akinon/next/types';
import { createSlice } from '@reduxjs/toolkit';

export interface SampleProductsState {
  allowed_quantity: number;
  sample_products: any[];
  isSampleProductActive: boolean;
  isSampleProductsModalOpen: boolean;
  isSampleProductsSaved: boolean;
}

const initialState: SampleProductsState = {
  allowed_quantity: 0,
  sample_products: [],
  isSampleProductActive: false,
  isSampleProductsModalOpen: false,
  isSampleProductsSaved: false
};

export interface TypePreOrderWithSample extends PreOrder {
  sample_products?: any;
}

const sampleProductsSlice = createSlice({
  name: 'sampleProductsReducer',
  initialState,
  reducers: {
    setSampleProducts: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSampleProductsModalOpen: (state, action) => {
      state.isSampleProductsModalOpen = action.payload;
    },
    setSampleProductsSaved: (state, action) => {
      state.isSampleProductsSaved = action.payload;
    }
  }
});

export const {
  setSampleProducts,
  setSampleProductsModalOpen,
  setSampleProductsSaved
} = sampleProductsSlice.actions;

export default sampleProductsSlice.reducer;
